<template>
  <div class="sidebar">
    <span class="sidebar-heading">Categorieen</span>
    <ul class="sidebar-links">
      <li
        v-for="sidebarLink of sidebarData"
        :key="'sidebarLink' + sidebarLink.id"
      >
        <b-link :to="sidebarLink.linkTo">{{ sidebarLink.itemName }}</b-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SidebarLinks",
  computed: {
    sidebarData() {
      return this.$store.getters["menu/getMenuItems"];
    },
  },
};
</script>
